@import "variables-custom";

$main-color: #AFBD20;


.main-color {
    background-color: #AFBD20;
}
.btn-width {
    min-width: 80px;
}

.btn-booking {
    --bs-btn-color: #000;
    --bs-btn-bg: #AFBD20;
    --bs-btn-border-color: #AFBD20;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #c3d015;
    --bs-btn-hover-border-color: #AFBD20;
    --bs-btn-focus-shadow-rgb: 217, 164, 6;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #def301;
    --bs-btn-active-border-color: #AFBD20;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #ffc107;
    --bs-btn-disabled-border-color: #ffc107;
}

.btn-link-main {
    --bs-btn-font-weight: 400;
    --bs-btn-color: #AFBD20;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #a4b404;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: #000;
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    text-decoration: underline;
}
.form-check-input {
    &checked {
        background-color: #AFBD20;
        border-color: #a4b404;
    }
}



